<template>
  <div>
    <h4><font-awesome-icon icon="list" /> {{ titulo }}</h4>
    <div class="card">
      <h6 class="card-header">Datos del Acta</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-3">
            <label for="no">No. de Acta</label>
            <input
              readonly="readonly"
              maxlength="40"
              v-model.trim="acta.no"
              class="form-control"
            />
          </div>

          <div class="form-group col-md-3">
            <label for="diocesis_id">Tipo de reunión</label>
            <b-form-select
              v-model="acta.acta_tipo_id"
              :disabled="acta.acta_id > 0"
              :options="tipos_acta"
              value-field="acta_tipo_id"
              text-field="descripcion"
              @change="actualizarAsistentes"
            >
              <b-form-select-option :value="null"
                >Seleccione</b-form-select-option
              >
            </b-form-select>

            <div class="errores" v-if="!$v.acta.acta_tipo_id.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="diocesis_id">Diócesis</label>
            <select
              :disabled="acta.acta_id > 0"
              v-model="acta.diocesis_id"
              class="form-control"
              v-on:change="actualizaSectores"
            >
              <option
                v-for="dio in diocesis"
                :value="dio.diocesis_id"
                :key="dio.diocesis_id"
              >
                {{ dio.nom_diocesis }}
              </option>
            </select>
            <div class="errores" v-if="!$v.acta.diocesis_id.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="sector_id">Sector o presector</label>
            <select
              :disabled="acta.acta_id > 0"
              v-model="acta.sector_id"
              @change="actualizarAsistentes"
              class="form-control"
            >
              <option
                v-for="sector in sectores"
                :value="sector.sector_id"
                :key="sector.sector_id"
              >
                {{ sector.nom_sector }}
              </option>
            </select>
            <!--Creo que esta validación que sigue está de más, porque cuando entra a esta ventana
            ya hay un Sector seleccionado, y además no te permite dejar en blanco este "Select"-->
            <div class="errores" v-if="!$v.acta.sector_id.required">
              Requerido
            </div>
          </div>

          <div class="form-group col-md-3">
            <label for="fecha">Fecha:</label>
            <input
              type="date"
              id="fecha"
              v-model="acta.fecha"
              class="form-control"
            />
            <div class="errores" v-if="!$v.acta.fecha.required">Requerido</div>
          </div>
          <div class="form-group col-md-3">
            <label for="fecha_envio">Fecha envío:</label>
            <input
              type="date"
              id="fecha_envio"
              v-model="acta.fecha_envio"
              class="form-control"
            />
            <div class="errores" v-if="!$v.acta.fecha_envio.required">Requerido</div>
          </div>
          <div class="form-group col-md-3">
            <label for="hora_ini">Hora de Inicio:</label>
            <input
              type="time"
              id="hora_ini"
              v-model.trim="acta.hora_ini"
              class="form-control"
            />
            <div class="errores" v-if="!$v.acta.hora_ini.required">
              Requerido
            </div>
          </div>

          <div class="form-group col-md-3">
            <label for="hora_fin">Hora de término:</label>
            <input
              type="time"
              id="hora_fin"
              v-model.trim="acta.hora_fin"
              class="form-control"
            />
            <div class="errores" v-if="!$v.acta.hora_fin.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <b-form-group label="Anfitrión">
              <b-form-select
                v-model="acta.membresia_id"
                :options="acta.asistentes"
                value-field="membresia_id"
                text-field="nombre_completo"
              >
              </b-form-select>
              <div class="errores" v-if="!$v.acta.membresia_id.required">
              Requerido
            </div>
            </b-form-group>
          </div>
          <div class="form-group col-md-3">
            <label for="lugar_acta">Lugar del acta</label>
            <input
              maxlength="50"
              v-model.trim="acta.lugar"
              class="form-control"
            />
            <div class="errores" v-if="!$v.acta.lugar.required">Requerido</div>
          </div>
          <div class="form-group col-md-3">
            <b-form-group label="Tipo de acta">
              <b-form-select
                v-model="acta.tipo"
                :options="['Ordinaria', 'Extraordinaria']"
              >
              </b-form-select>
            <div class="errores" v-if="!$v.acta.tipo.required">Requerido</div>
            </b-form-group>
          </div>
          <div class="form-group col-md-6">
            <label for="internos">Acuerdos internos</label>
            <textarea
              v-model.trim="acta.internos"
              class="form-control"
              rows="3"
            />
          </div>
          <div class="form-group col-md-6">
            <label for="informes">Puntos a informar a {{ jerarquia == Constantes.JERARQUIA_DIOCESANO ? 'SNR' : 'ECD'  }}</label>
            <textarea
              v-model.trim="acta.informes"
              class="form-control"
              rows="3"
            />
          </div>
          <div class="form-group col-md-3">
            <label for="adjunto" class="upload-label">Adjuntar archivo</label>
            <archivo-pdf v-model="acta.adjunto" />
          </div>
        </div>
        <!-- form-row Datos del acta -->
      </div>
      <!-- card-body Datos del acta -->
    </div>
    <!-- card Datos del acta -->
    <br />
    <div class="card">
      <h6 class="card-header">Asistentes</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="col-md-6">
            No. participantes: {{ acta.asistentes.length }}
          </div>
          <table class="table table-bordered">
            <tr>
              <th>Rol</th>
              <th>Responsable</th>
              <th>Asiste</th>
              <th v-if="tipo_membresia == Constantes.TIPO_MATRIMONIO">Asiste ella</th>
            </tr>
            <tr
              v-for="(asistente, index) in $v.acta.asistentes.$each.$iter"
              :key="index"
            >
              <td>
                {{ asistente.$model.nom_rol }}
              </td>
              <td>
                {{ asistente.$model.nombre_completo }}
              </td>
              <td>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="asistente.$model.asistencia1"
                  />
                </div>
              </td>
              <td>
                <div
                  class="form-check"
                  v-if="
                    asistente.$model.tipo_membresia_id ==
                    Constantes.TIPO_MATRIMONIO
                  "
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="asistente.$model.asistencia2"
                  />
                </div>
              </td>
            </tr>
          </table>
          <br />
        </div>
        <!-- form-row Asistencia -->
      </div>
      <!-- card-body Asistencia -->
    </div>
    <br />
    <div class="card">
      <h6 class="card-header">Orden del día</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="col-md-6">No. puntos: {{ acta.puntos.length }}</div>
          <div class="col-md-6 text-right botones-accion">
            <button class="btn btn-secondary" @click="agregarPunto">
              <font-awesome-icon icon="plus" />
              Agregar
            </button>
          </div>
          <table class="table table-bordered">
            <tr>
              <th>Descripción</th>
              <th>Presenta</th>
              <th>Tiempo</th>
            </tr>
            <tr
              v-for="(punto, index) in $v.acta.puntos.$each.$iter"
              :key="index"
            >
              <td>
                <input
                  class="form-control"
                  type="text"
                  v-model="punto.$model.descripcion"
                />
                <div class="errores" v-if="!punto.descripcion.required">
                  Requerido
                </div>
              </td>
              <td>
                <input
                  class="form-control"
                  type="text"
                  v-model="punto.$model.presenta"
                />
                <div class="errores" v-if="!punto.presenta.required">
                  Requerido
                </div>
              </td>
              <td>
                <input
                  class="form-control"
                  type="text"
                  v-model="punto.$model.tiempo"
                />
                <div class="errores" v-if="!punto.tiempo.required">
                  Requerido
                </div>
                <div class="errores" v-if="!punto.tiempo.numeros">
                  Ingrese solo números
                </div>
              </td>
              <td>
                <button
                  class="btn btn-secondary"
                  title="Quitar"
                  v-on:click="quitarPunto(index)"
                >
                  <font-awesome-icon size="lg" icon="trash" />
                </button>
              </td>
            </tr>
          </table>
        </div>
        <!-- form-row Acuerdos -->
      </div>
      <!-- card-body Acuerdos -->
    </div>
    <br />
    <div class="card">
      <h6 class="card-header">Acuerdos</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="col-md-6">No. acuerdos: {{ acta.acuerdos.length }}</div>
          <div class="col-md-6 text-right botones-accion">
            <button class="btn btn-secondary" @click="agregarAcuerdo">
              <font-awesome-icon icon="plus" />
              Agregar
            </button>
          </div>
          <table class="table table-bordered">
            <tr>
              <th>Asunto</th>
              <th>Responsable</th>
              <th>Compromiso</th>
              <th>Fecha</th>
            </tr>
            <tr
              v-for="(acuerdo, index) in $v.acta.acuerdos.$each.$iter"
              :key="index"
            >
              <td>
                <input
                  class="form-control"
                  type="text"
                  v-model="acuerdo.$model.asunto"
                />
                <div class="errores" v-if="!acuerdo.asunto.required">
                  Requerido
                </div>
              </td>
              <td>
                <b-form-select
                  v-model="acuerdo.$model.membresia_id"
                  :options="acta.asistentes"
                  value-field="membresia_id"
                  text-field="nombre_completo"
                >
                </b-form-select>
                <div class="errores" v-if="!acuerdo.membresia_id.required">
                  Requerido
                </div>
              </td>
              <td>
                <input
                  class="form-control"
                  type="text"
                  v-model="acuerdo.$model.compromiso"
                />
                <div class="errores" v-if="!acuerdo.compromiso.required">
                  Requerido
                </div>
              </td>
              <td>
                <input
                  class="form-control"
                  type="date"
                  v-model="acuerdo.$model.fecha_limite"
                />
                <div class="errores" v-if="!acuerdo.fecha_limite.required">
                  Requerido
                </div>
              </td>
              <td>
                <button
                  class="btn btn-secondary"
                  title="Quitar"
                  v-on:click="quitarAcuerdo(index)"
                >
                  <font-awesome-icon size="lg" icon="trash" />
                </button>
              </td>
            </tr>
          </table>
        </div>
        <!-- form-row Acuerdos -->
      </div>
      <!-- card-body Acuerdos -->
    </div>
    <!-- card Acuerdos -->
    <div class="card">
      <h6 class="card-header">Próxima reunión</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-3">
            <label for="proxima_reunion">Fecha:</label>
            <input
              type="date"
              id="proxima_reunion"
              v-model="acta.proxima_reunion"
              class="form-control"
            />
            <!--<div class="errores" v-if="!$v.acta.proxima_reunion.required">Requerido</div>-->
          </div>
          <div class="form-group col-md-3">
            <label for="proxima_hora">Hora de Inicio:</label>
            <input
              type="time"
              id="proxima_hora"
              v-model.trim="acta.proxima_hora"
              class="form-control"
            />
            <!--<div class="errores" v-if="!$v.acta.proxima_hora.required">
              Requerido
            </div>-->
          </div>
          
          <div class="form-group col-md-3">
            <b-form-group label="Anfitrión">
              <b-form-select
                v-model="acta.proxima_membresia_id"
                :options="acta.asistentes"
                value-field="membresia_id"
                text-field="nombre_completo"
              >
              </b-form-select>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="col-md-12 text-center botones-accion">
      <button
        class="btn btn-primary"
        :disabled="guardando"
        v-on:click.stop.prevent="guardar"
      >
        Guardar
      </button>
      <button
        class="btn btn-secondary"
        v-on:click.stop.prevent="$router.go(-1)"
      >
        Regresar
      </button>
    </div>

    <b-modal ref="id" title="Importar de reunión funcional">
      <!-- Si el registro es con ID de miembro -->
      <form @submit.prevent="guardarId()">
        <b-form-group label="ID">
          <b-form-input v-model="reunion.id"></b-form-input>
          <div class="errores" v-if="!$v.reunion.id.required">Requerido</div>
          <div class="errores" v-if="!$v.reunion.id.numeros">
            Ingrese solo números
          </div>
        </b-form-group>
      </form>
      <template #modal-footer="{ hide }">
        <b-button variant="primary" class="float-right" @click="guardarId()">
          Registrar
        </b-button>
        <b-button class="float-right" @click="hide()"> Cancelar </b-button>
      </template>
    </b-modal>
    <b-modal
      v-model="guardando"
      title="Guardando"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      hide-footer
    >
      <div class="text-center">
        <barra-progreso v-model="avanceGuardando" />
      </div>
    </b-modal>
  </div>
</template>
<script>
import api from "@/api";
import moment from "moment";
import { Constantes } from "@/api";
import { required } from "vuelidate/lib/validators";
import { numeros } from "@/libs/validadores";
import ArchivoPdf from "@/components/ArchivoPdf.vue";
import { exportarExcel } from "@/utils";
import BarraProgreso from "@/components/BarraProgreso";

export default {
  name: "ActasEditar",
  components: { ArchivoPdf, BarraProgreso },
  data() {
    return {
      Constantes: Constantes,
      modals: {
        registroMembresia: false,
        registroNoMFC: false,
        acuerdos: false,
        fechaLimite: null,
      },
      diocesis: [],
      sectores: [],
      tipos_acta: [],
      guardando: false,
      avanceGuardando: 0,
      roles: [],
      reunion: { id: null },

      titulo: "",
      acta: {
        acta_id: null,
        acta_tipo_id: [],
        fecha: moment(Date.now()).format("YYYY-MM-DD"),
        hora_ini: null,
        hora_fin: null,
        lugar: null,
        orden: null,
        rol_id: null, //este campo al parecer no existe en la tabla "acta". Anteriormente era "capacitador"
        observaciones: null,
        asistentes: [],
        puntos: [],
        acuerdos: [],
      },

      permisos: {
        agregar: ["", "acta-agregar"],
        editar: ["", "acta-editar"],
        estatus: ["", "acta-estatus"],
      },
    };
  },
  validations() {
    return {
      acta: {
        acta_tipo_id: { required },
        diocesis_id: { required },
        sector_id: { required },
        fecha: { required },
        fecha_envio: { required },
        hora_ini: { required },
        hora_fin: { required },
        lugar: { required },
        membresia_id: { required },
        tipo: { required },
        acuerdos: {
          $each: {
            asunto: { required },
            compromiso: { required },
            membresia_id: { required },

            fecha_limite: { required },
          },
        },
        puntos: {
          $each: {
            descripcion: { required },
            presenta: { required },

            tiempo: { required, numeros },
          },
        },
        asistentes: {
          $each: {
            el: { required: false },
            ella: { required: false, },
          },
        },
      },
      reunion: {
        id: { required, numeros },
      },
    };
  },
  //  };
  methods: {
    actualizarAsistentes() {
      console.log(this.acta.acta_tipo_id);
      let tipo_acta = this.tipos_acta.filter(
        (t) => t.acta_tipo_id == this.acta.acta_tipo_id
      );
      let jerarquia_id = null;
      let roles = [];
      if (Array.isArray(tipo_acta)) {
        tipo_acta = tipo_acta[0];
        jerarquia_id = tipo_acta.jerarquia_id;
        roles = tipo_acta.roles.map((r) => r.rol_id);
      }
      let sector_id = this.acta.sector_id;

      if (sector_id && jerarquia_id) {
        let datos = {
          jerarquia_id: jerarquia_id,
          roles: roles,
          sector_id: sector_id,
        };
        api.asistentesActa(datos).then((r) => {
          this.acta.asistentes = r.data;
        });
      }
    },

    agregarAcuerdo() {
      this.acta.acuerdos.push({});
    },
    quitarAcuerdo: function (pos) {
      if (this.acta.acuerdos[pos].acuerdo_id) {
        this.acta.acuerdos_quitar.push(this.acta.acuerdos[pos].acuerdo_id);
      }
      this.acta.acuerdos.splice(pos, 1);
    },
    agregarPunto() {
      this.acta.puntos.push({});
    },
    quitarPunto: function (pos) {
      if (this.acta.puntos[pos].orden_id) {
        this.acta.puntos_quitar.push(this.acta.puntos[pos].orden_id);
      }
      this.acta.puntos.splice(pos, 1);
    },

    showModal(modalName) {
      this.modals[modalName] = true;
    },

    exportarExcel() {
      exportarExcel(this.acta.asistentes);
    },
    cambioNombre() {
      console.log(this.nombre.nombre);
      const nombre = this.nombre.nombre.trim();
      if (nombre && nombre.length >= 3) {
        api
          .registrarBuscarParticipanteNombre(
            this.nombre.tipo_membresia_id ?? 0,
            this.nombre.nombre
          )
          .then((r) => {
            const resp = r.data;
            this.nombre.membresia_id = null;
            if (resp.respuesta) {
              this.asistentesEncontrados = r.data.encontrados;
            } else {
              this.$parent.error(resp.mensaje);
            }
          });
      }
    },

    guardarNombre() {
      this.$v.nombre.$touch();
      if (this.$v.nombre.$invalid) {
        this.$parent.error(
          "Faltan datos por registrar o existen datos incorrectos"
        );
        return;
      }
      if (
        this.acta.asistentes.some(
          (p) => p.membresia_id == this.nombre.membresia_id
        )
      ) {
        this.$parent.error("Participante ya registrado");
        return;
      }
      api
        .registrarParticipanteId(this.acta.acta_id, this.nombre.membresia_id)
        .then((response) => {
          const resp = response.data;
          if (resp.respuesta) {
            this.acta.asistentes.unshift(resp.asistente);
            this.$refs.nombre.hide();
          } else {
            this.$parent.error(resp.mensaje);
          }
        });
    },

    guardarId() {
      this.$v.id.$touch();
      if (this.$v.id.$invalid) {
        this.$parent.error(
          "Faltan datos por registrar o existen datos incorrectos"
        );
        return;
      }
      if (this.acta.asistentes.some((p) => p.membresia_id == this.id.id)) {
        this.$parent.error("Participante ya registrado");
        return;
      }
      api
        .registrarParticipanteId(this.acta.acta_id, this.id.id)
        .then((response) => {
          const resp = response.data;
          if (resp.respuesta) {
            this.acta.asistentes.unshift(resp.asistente);
            this.$refs.id.hide();
          } else {
            this.$parent.error(resp.mensaje);
          }
        });
    },

    guardarQr(qr) {
      api.registrarParticipanteQr(this.acta.acta_id, qr).then((response) => {
        const resp = response.data;
        if (resp.respuesta) {
          if (
            this.acta.asistentes.some(
              (p) => p.membresia_id == resp.asistente.membresia_id
            )
          ) {
            this.$parent.error("Participante ya registrado");
            return;
          }
          this.acta.asistentes.unshift(resp.asistente);
          this.$refs.credencial.hide();
        } else {
          this.$parent.error(resp.mensaje);
        }
      });
    },

    guardarIdD() {
      this.$v.idd.$touch();
      if (this.$v.idd.$invalid) {
        this.$parent.error(
          "Faltan datos por registrar o existen datos incorrectos"
        );
        return;
      }
      api
        .registrarParticipanteIdd(
          this.acta.acta_id,
          this.idd.diocesis_id,
          this.idd.tipo_membresia_id,
          this.idd.id
        )
        .then((response) => {
          const resp = response.data;
          if (resp.respuesta) {
            if (
              this.acta.asistentes.some(
                (p) => p.membresia_id == resp.asistente.membresia_id
              )
            ) {
              this.$parent.error("Participante ya registrado");
              return;
            }

            this.acta.asistentes.unshift(resp.asistente);
            this.$refs.idd.hide();
          } else {
            this.$parent.error(resp.mensaje);
          }
        });
    },
    guardarNoMfc() {
      this.$v.nomfc.$touch();
      if (this.$v.nomfc.$invalid) {
        this.$parent.error(
          "Faltan datos por registrar o existen datos incorrectos"
        );
        return;
      }
      api
        .registrarParticipanteNo(this.acta.acta_id, this.nomfc)
        .then((response) => {
          const resp = response.data;
          if (resp.respuesta) {
            this.acta.asistentes_no_mfc.unshift(resp.asistente);
            this.$refs.nomfc.hide();
          } else {
            this.$parent.error(resp.mensaje);
          }
        });
    },

    submitForm() {
      // Aquí ponemos el código de lo que queremos hacer con los datos capturados
      console.log("Nombre:", this.nombre);
      console.log("Dirección:", this.direccion);
      console.log("Teléfono:", this.telefono);
      console.log("Correo electrónico:", this.correo);
      console.log("Casado por la Iglesia", this.casado_iglesia);

      //Aquí van los otros campos que se quieren registrar en el acta
      this.closeModal();
    },
    avance(progreso) {
      this.avanceGuardando = Math.round((progreso.loaded * 100) / progreso.total);
    },
    guardar() {
      this.$v.acta.$touch();
      console.log(this.$v.acta.$errors);
      let valido = !this.$v.acta.$invalid;
      console.log(this.$v.acta);
      if (valido) {
        if (this.guardando) {
          return;
        }
        this.guardando = true;

        api
          .guardarActa(this.acta, this.avance)
          .then((response) => {
            let datos = response.data;
            if (datos != null && datos.respuesta) {
              this.acta.acta_id = datos.id;
              this.$router.go(-1);
              this.$parent.mensaje("Información guardada correctamente");
            } else {
              this.$parent.error(
                "Error al guardar la información: " +
                  (datos != null ? datos.mensaje : "error interno")
              );
            }
            this.guardando = false;
          })
          .catch((error) => {
            this.guardando = false;
            this.$parent.error("Error al guardar la información: " + error);
          });
      } else {
        this.$parent.error(
          "Faltan datos por registrar o existen datos incorrectos"
        );
      }
    },
    listaActaTipos: function () {
      api.listarActasTipo(this.tipo_membresia, this.jerarquia).then((r) => {
        this.tipos_acta = r.data;
      });
    },

    listaDiocesis: function () {
      api.listarDiocesis().then((r) => {
        this.diocesis = r.data;
      });
    },

    listaRoles() {
      api.listarRolesTodos().then((r) => {
        if (Array.isArray(r.data)) {
          this.roles = r.data;
        } else {
          this.roles = [];
        }
      });
    },

    actualizaSectores: function () {
      var id = this.acta.diocesis_id;
      this.sectores = [];
      api.listarSectores(id).then((r) => {
        this.sectores = r.data;
      });
    },

    obtenerActa: function (id) {
      api.obtenerActa(id).then((r) => {
        this.acta = r.data.acta;
        this.tipo_membresia = this.acta.tipo_membresia_id;
        this.jerarquia = this.acta.jerarquia_id;
        this.actualizaSectores();
        this.listaActaTipos();
        this.titulo =
          "Edición de acta de " +
          Constantes.TIPOS_MEMBRESIA[this.tipo_membresia];
      });
    },
  },
  computed: {},
  mounted: function () {
    const tipo_membresia = parseInt(this.$route.params.tipo) ?? 0;
    const jerarquia = parseInt(this.$route.params.nivel) ?? 0;
    if (this.$route.params.id) {
      this.obtenerActa(this.$route.params.id);
    } else {
      this.tipo_membresia = tipo_membresia;
      this.jerarquia = jerarquia;
      this.titulo =
        "Agregar acta "+Constantes.JERARQUIAS[this.jerarquia]+" " + Constantes.TIPOS_MEMBRESIA[this.tipo_membresia];
      this.listaActaTipos();
    }
    this.listaDiocesis();
  },
};
</script>
<style scoped>
.acuerdo-number {
  font-size: 1.2em; /* Tamaño de fuente más grande */
  color: #007bff; /* Color de resaltado, puedes ajustarlo a tu preferencia */
  font-weight: bold; /* Texto en negrita */
}

.underlined-text {
  text-decoration: underline; /* Subrayar el texto */
}
</style>
