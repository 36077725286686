<template>
  <div>
    <h4>{{ titulo }} con estatus de "Posible baja"</h4>
    <filtros-membresia v-model="busqueda" ref="busqueda" :tipo-membresia="tipo" :estatus-id="22" :mostrar-estado="false"
      @input="actualizar" />
    <div class="row acciones">
      <div class="col-6 text-left">
        Registros encontrados: {{ listadoMembresias.length }}
      </div>
      <div class="col-6 botones-accion">
        <b-button @click="exportarExcel" :disabled="listadoMembresias.length == 0">Exportar Excel</b-button>
      </div>
    </div>
    <ve-table rowKeyFieldName="membresia_id" border-y :fixed-header="true" :columns="columnas"
      :table-data="listadoMembresias" :max-height="400" :cell-selection-option="{ enable: false }"
      :virtualScrollOption="{ enable: true }" />
    <b-modal ref="modalConfirmarBaja" title="Confirmar baja">
      <b-form-row>
        <b-col cols="12">
          <b-form-group label="Nombre">
            <b-input readonly v-model="membresia_baja.nombre_completo"></b-input>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Causa de posible baja">
            <b-input readonly v-model="membresia_baja.motivo_baja"></b-input>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Acción">
            <b-form-select v-model="membresia_baja.accion"
              :options="[{ 'id': 1, 'nombre': 'Confirmar baja' }, { 'id': 2, 'nombre': 'Cambiar estatus a alta' }]"
              value-field="id" text-field="nombre">
              <b-form-select-option>Seleccione</b-form-select-option>
            </b-form-select>
            <div class="errores" v-if="!$v.membresia_baja.accion.required">
              Requerido
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" v-if="membresia_baja.accion == 1">
          <b-form-group label="Fecha de baja">
            <b-input type="date" v-model="membresia_baja.fecha"></b-input>
          </b-form-group>
          <div class="errores" v-if="!$v.membresia_baja.fecha.required">
            Requerido
          </div>
        </b-col>
      </b-form-row>
      <template #modal-footer="{ hide }">
        <b-button variant="primary" class="float-right" @click="confirmar()">
          Confirmar
        </b-button>
        <b-button class="float-right" @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from "@/api";
import { API_URL } from "@/api";
import { Constantes } from "@/api";
import FiltrosMembresia from "@/components/FiltrosMembresia.vue";
import { nextTick } from 'vue';
import { exportarExcel } from "@/utils"
import { required } from "vuelidate/lib/validators";
import { requiredIf } from "vuelidate/lib/validators";

export default {
  name: "Membresia",
  components: { FiltrosMembresia },
  data() {
    return {
      API_URL: API_URL,
      Constantes: Constantes,
      listadoMembresias: [],
      membresia_baja: {
        motivo: null
      },
      causas_baja: [],
      tipo: Number.parseInt(this.$route.params.tipo),
      titulo:
        Constantes.TIPOS_MEMBRESIA[Number.parseInt(this.$route.params.tipo)],
      permisos: {
        agregar: ['', 'membresia-matrimonios-agregar', 'membresia-jovenes-agregar', 'membresia-mares-agregar', 'membresia-ae-agregar', 'membresia-adolescentes-agregar'],
        editar: ['', 'membresia-matrimonios-editar', 'membresia-jovenes-editar', 'membresia-mares-editar', 'membresia-ae-editar', 'membresia-adolescentes-editar'],
        status: ['', 'membresia-matrimonios-status', 'membresia-jovenes-status', 'membresia-mares-status', 'membresia-ae-status', 'membresia-adolescentes-status'],
        credencial: ['', 'membresia-matrimonios-credencial', 'membresia-jovenes-credencial', 'membresia-mares-credencial', 'membresia-ae-credencial', 'membresia-adolescentes-credencial'],
        posible_baja: ['', 'membresia-matrimonios-posible_baja', 'membresia-jovenes-posible_baja', 'membresia-mares-posible_baja', 'membresia-ae-posible_baja', 'membresia-adolescentes-posible_baja'],
        baja: ['', 'membresia-matrimonios-baja', 'membresia-jovenes-baja', 'membresia-mares-baja', 'membresia-ae-baja', 'membresia-adolescentes-baja'],
      },
      busqueda: {},
      columnas: [
        {
          field: "membresia_id",
          key: "a",
          title: "Id",
          align: "left",
        },
        {
          field: "diocesis",
          key: "b",
          title: "Diócesis",
          align: "left",
        },
        {
          field: "sector",
          key: "c",
          title: "Sector",
          align: "left",
        },
        {
          field: "nombre_completo",
          key: "d",
          title: "Nombre",
          align: "left",
        },
        {
          field: "rol",
          key: "e",
          title: "Rol",
          align: "left",
        },
        {
          field: "estatus",
          key: "f",
          title: "Estatus",
          align: "left",
        },

        {
          field: "nivel",
          key: "g",
          title: "Nivel",
          align: "center",
        },
        {
          renderBodyCell: (d) => {
            return (
              <span>
                <a
                  href="javascript:;"
                  onClick={() => this.modalConfirmarBaja(d.row)}
                  v-show={this.$parent.permisos(this.permisos.baja[this.tipo])}
                >
                  <font-awesome-icon size="lg" icon="pen" />
                </a>
              </span>
            );
          },
          key: "h",
          title: "Acciones",
          align: "center",
        },
      ],
    };
  },
  validations() {
    return {
      membresia_baja: {
        accion: {
          required,
        },
        fecha: {
          required: requiredIf(function (s) {
            return s.accion == 1;
          }),
        }
      },
    };
  },
  methods: {
    exportarExcel() {
      exportarExcel(this.listadoMembresias);
    },
    actualizar() {
      api.reporteBajasTipo(this.tipo, this.busqueda).then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoMembresias = r.data;
        } else {
          this.listadoMembresias = [];
        }
      });
    },
    confirmar() {
      api
        .confirmarBajaMembresia(this.membresia_baja)
        .then((resp) => {
          let r = resp.data;
          if (r.respuesta) {
            this.$refs.modalConfirmarBaja.hide();
            this.actualizar();
          } else {
            this.$parent.error(
              "Error al completar la baja " + r.mensaje
            );
          }
        })
        .catch((error) => {
          this.$parent.error("Error al completar la baja " + error);
        });
    },
    modalConfirmarBaja(membresia) {
      this.membresia_baja = membresia;
      this.membresia_baja.motivo = membresia.motivo_baja;
      this.$refs.modalConfirmarBaja.show();
    },
    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
  },
  mounted() {
    this.$watch(
      () => this.$route.params,
      async () => {
        this.tipo = Number.parseInt(this.$route.params.tipo);
        this.titulo = Constantes.TIPOS_MEMBRESIA[this.tipo];
        this.busqueda = {};
        this.listadoMembresias = [];
        await nextTick();
        this.$refs.busqueda.inicializa(this.tipo);
      }
    );
  },
};
</script>