<template>
  <div>
    <h4><font-awesome-icon icon="list" /> {{ titulo }}</h4>
    <div class="card">
      <h6 class="card-header">Datos del Acta</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-3">
            <label for="acta_id">No. de Acta</label>
            <span class="form-info">
            {{ acta.no }}
            </span>
          </div>

          <div class="form-group col-md-3">
            <label for="diocesis_id">Tipo de reunión</label>
            <div class="form-info">
              {{ tipos_acta.filter(s => s.acta_tipo_id == acta.acta_tipo_id)[0].descripcion }}
            </div>                      
          </div>
          <div class="form-group col-md-3">
            <label for="diocesis_id">Diócesis</label>
            <div class="form-info">
              {{ diocesis.filter(s => s.diocesis_id == acta.diocesis_id)[0].nom_diocesis }}
            </div>            
          </div>
          <div class="form-group col-md-3">
            <label for="sector_id">Sector o presector</label>
            <div class="form-info">
              {{ sectores.filter(s => s.sector_id == acta.sector_id)[0].nom_sector }}
            </div>            
          </div>

          <div class="form-group col-md-3">
            <label for="fecha">Fecha:</label>
            <div class="form-info">
              {{ acta.fecha }}
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="fecha_envio">Fecha envío:</label>
            <div class="form-info">
              {{ acta.fecha_envio }}
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="hora_ini">Hora de Inicio:</label>
            <div class="form-info">
              {{ acta.hora_ini }}
            </div>
          </div>

          <div class="form-group col-md-3">
            <label for="hora_fin">Hora de término:</label>
            <div class="form-info">
              {{ acta.hora_fin }}
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="hora_fin">Anfitrión:</label>
            <div class="form-info">
              {{ asistentes[acta.membresia_id]?.nombre_completo }}
            </div>

          </div>
          <div class="form-group col-md-3">
            <label for="lugar_acta">Lugar del acta</label>
            <div class="form-info">
              {{ acta.lugar }}
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="orden_dia">Tipo de acta</label>
            <div class="form-info">
              {{ acta.tipo }}
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="observaciones">Acuerdos internos</label>
            <textarea readonly="true" v-model="acta.internos" rows="5" class="form-control">
            </textarea>
          </div>
          <div class="form-group col-md-6">
            <label for="observaciones">Puntos a informar a {{ jerarquia == Constantes.JERARQUIA_DIOCESANO ? 'SNR' : 'ECD'  }}</label>
            <textarea readonly="true" v-model="acta.informes" rows="5" class="form-control">
            </textarea>
          </div>
          <div class="form-group col-md-6" v-if="acta.adjunto != null">
            <label>Adjunto</label>
            <div class="form-info">
              <a target="_blank" :href="`${API_URL}/archivo/${acta.adjunto.ruta}`" >
                <font-awesome-icon icon="file-pdf" />
                Documento.pdf
              </a>
            </div>
          </div>
          
        </div>
        <!-- form-row Datos del acta -->
      </div>
      <!-- card-body Datos del acta -->
    </div>
    <!-- card Datos del acta -->
    <br />
    <div class="card">
      <h6 class="card-header">Asistentes</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="col-md-6">
            No. participantes: {{ acta.asistentes.length }}
          </div>
          <table class="table table-bordered">
            <tr>
              <th>Rol</th>
              <th>Responsable</th>
              <th>Asiste</th>
              <th v-if="tipo_membresia == Constantes.TIPO_MATRIMONIO">Asiste ella</th>
            </tr>
            <tr
              v-for="(asistente, index) in acta.asistentes"
              :key="index"
            >
              <td>
                {{ asistente.nom_rol }}
              </td>
              <td>
                {{ asistente.nombre_completo }}
              </td>
              <td>
                <font-awesome-icon size="lg" icon="check-square" v-show="asistente.asistencia1" />
              </td>
              <td>
                <font-awesome-icon size="lg" icon="check-square" v-show="asistente.asistencia2" />
              </td>
            </tr>
          </table>
          <br />
        </div>
        <!-- form-row Asistencia -->
      </div>
      <!-- card-body Asistencia -->
    </div>
    <br />
    <div class="card">
      <h6 class="card-header">Orden del día</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="col-md-6">No. puntos: {{ acta.puntos.length }}</div>
          <div class="col-md-6 text-right botones-accion">
          </div>
          <table class="table table-bordered">
            <tr>
              <th>Descripción</th>
              <th>Presenta</th>
              <th>Tiempo</th>
            </tr>
            <tr
              v-for="(punto, index) in acta.puntos"
              :key="index"
            >
              <td>
                {{ punto.descripcion }}
              </td>
              <td>
                {{  punto.presenta }}
              </td>
              <td>
                {{ punto.tiempo }}
              </td>
            </tr>
          </table>
        </div>
        <!-- form-row Acuerdos -->
      </div>
      <!-- card-body Acuerdos -->
    </div>
    <br />
    <div class="card">
      <h6 class="card-header">Acuerdos</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="col-md-6">No. acuerdos: {{ acta.acuerdos.length }}</div>
          <table class="table table-bordered">
            <tr>
              <th>Asunto</th>
              <th>Responsable</th>
              <th>Compromiso</th>
              <th>Fecha</th>
            </tr>
            <tr
              v-for="(acuerdo, index) in acta.acuerdos"
              :key="index"
              >
              <td>
                {{ acuerdo.asunto }}
              </td>
              <td>
                {{ asistentes[acuerdo.membresia_id].nombre_completo }}
              </td>
              <td>
                {{  acuerdo.compromiso }}
              </td>
              <td>
                {{  acuerdo.fecha_limite }}
              </td>
            </tr>
          </table>
        </div>
        <!-- form-row Acuerdos -->
      </div>
      <!-- card-body Acuerdos -->
    </div>
    <!-- card Acuerdos -->
    <div class="card">
      <h6 class="card-header">Próxima reunión</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-3">
            <label for="proxima_reunion">Fecha:</label>
            <div class="form-info">
              {{ acta.proxima_reunion || 'No definida' }}
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="proxima_hora">Hora de Inicio:</label>
            <div class="form-info">
              {{ acta.proxima_hora || 'No definida' }}
            </div>
          </div>
          <div class="form-group col-md-3">
            
            <b-form-group label="Anfitrión:">
            <div class="form-info">
              {{ asistentes[acta.proxima_membresia_id]?.nombre_completo || 'No definido' }}
            </div>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="col-md-12 text-center botones-accion">
      <button
        class="btn btn-secondary"
        v-on:click.stop.prevent="$router.go(-1)"
      >
        Regresar
      </button>
    </div>

    
  </div>
</template>
<script>
import api from "@/api";
import moment from "moment";
import { Constantes } from "@/api";
import { API_URL } from "@/api";

export default {
  name: "ActasConsultar",
  components: {  },
  data() {
    return {
      API_URL: API_URL,
      Constantes: Constantes,
      modals: {
        registroMembresia: false,
        registroNoMFC: false,
        acuerdos: false,
        fechaLimite: null,
      },
      diocesis: [],
      sectores: [],
      tipos_acta: [],
      guardando: false,
      roles: [],
      asistentes: [],
      reunion: { id: null },

      titulo: "",
      acta: {
        acta_id: null,
        acta_tipo_id: [],
        fecha: moment(Date.now()).format("YYYY-MM-DD"),
        hora_ini: null,
        hora_fin: null,
        lugar: null,
        orden: null,
        rol_id: null, //este campo al parecer no existe en la tabla "acta". Anteriormente era "capacitador"
        observaciones: null,
        asistentes: [],
        puntos: [],
        acuerdos: [],
      },

      permisos: {
        agregar: ["", "acta-agregar"],
        editar: ["", "acta-editar"],
        estatus: ["", "acta-estatus"],
      },
    };
  },
  //  };
  methods: {
    listaActaTipos: function () {
      api.listarActasTipo(this.tipo_membresia, this.jerarquia).then((r) => {
        this.tipos_acta = r.data;
      });
    },

    listaDiocesis: function () {
      api.listarDiocesis().then((r) => {
        this.diocesis = r.data;
      });
    },

    listaRoles() {
      api.listarRolesTodos().then((r) => {
        if (Array.isArray(r.data)) {
          this.roles = r.data;
        } else {
          this.roles = [];
        }
      });
    },

    actualizaSectores: function () {
      var id = this.acta.diocesis_id;
      this.sectores = [];
      api.listarSectores(id).then((r) => {
        this.sectores = r.data;
      });
    },

    obtenerActa: function (id) {
      api.obtenerActa(id).then((r) => {
        this.acta = r.data.acta;
        this.tipo_membresia = this.acta.tipo_membresia_id;
        this.jerarquia = this.acta.jerarquia_id;
        this.actualizaSectores();
        this.listaActaTipos();
        this.titulo =
          "Acta de " +
          Constantes.TIPOS_MEMBRESIA[this.tipo_membresia];

        this.asistentes = [];
        this.acta.asistentes.forEach (asis => {
          this.asistentes[asis.membresia_id] = asis;
        })
      });
    },
  },
  computed: {},
  mounted: function () {
    this.obtenerActa(this.$route.params.id);
    this.listaDiocesis();
  },
};
</script>
<style scoped>
.acuerdo-number {
  font-size: 1.2em; /* Tamaño de fuente más grande */
  color: #007bff; /* Color de resaltado, puedes ajustarlo a tu preferencia */
  font-weight: bold; /* Texto en negrita */
}

.underlined-text {
  text-decoration: underline; /* Subrayar el texto */
}
</style>
