import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/api'
import * as utils from '@/utils'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    usuario: null,
    permisos: null,
    token: null,
    ciclo: null,
    filtros: null,
  },
  mutations: {
    consultarFiltros(state) {
      if (state.filtros == null) {
        let temp = localStorage.getItem('filtros');
        if (temp != null) {
          try {
            state.filtros = JSON.parse(temp);
          } catch (e) { state.filtros = {}; }
        } else {
          state.filtros = {};
        }
      }
    },
    guardarFiltros(state, filtros) {
      for (const prop in filtros) {
        state.filtros[prop] = filtros[prop];
      }
      localStorage.setItem('filtros', JSON.stringify(state.filtros));
    },
    asignaCiclo(state, datos) {
      state.ciclo = datos;
    },
    asignaSesion(state, datos) {
      state.usuario = datos.membresia;
      state.token = datos.token;
      state.permisos = datos.permisos;
      localStorage.setItem('usuario', JSON.stringify(datos.membresia));
      localStorage.setItem('token', JSON.stringify(datos.token));
      localStorage.setItem('permisos', JSON.stringify(datos.permisos));
      localStorage.setItem('filtros', {});
      state.filtros = {};
    },
    reasignaSesion(state, datos) {
      state.token = datos.token;
      localStorage.setItem('token', JSON.stringify(datos.token));
    },
    terminaSesion(state) {
      state.token = null;
      state.usuario = null;
      state.permisos = null;
      localStorage.setItem('usuario', null);
      localStorage.setItem('token', null);
      localStorage.setItem('permisos', null);
      localStorage.setItem('filtros', {});
    },
    restauraSesion(state) {
      console.log("Restaurar sesion");
      if (state.token == null) {
        let temp = localStorage.getItem('token');
        if (temp != null) {
          try {
            state.token = JSON.parse(temp);
          } catch (e) { console.log(e); }
        }
      }
      if (state.usuario == null) {
        let temp = localStorage.getItem('usuario');
        if (temp != null) {
          try {
            state.usuario = JSON.parse(temp);
          } catch (e) { console.log(e); }
        }
      }
      if (state.permisos == null) {
        let temp = localStorage.getItem('permisos');
        if (temp != null) {
          try {
            state.permisos = JSON.parse(temp);
          } catch (e) { console.log(e); }
        }
      }
    },
  },
  actions: {
    guardarFiltros(context, filtros) {
      context.commit("guardarFiltros", filtros)
    },
    consultarFiltros(context) {
      context.commit("consultarFiltros")
    },
    consultarCiclo(context, datos) {
      return new Promise((resolve, reject) => {
        api.obtenerCicloVigente(datos)
          .then((respuesta) => {
            let r = respuesta.data
            context.commit('asignaCiclo', r);
            utils.EventBus.$emit('datos-ciclo');
            resolve(r);
          }).catch((r) => {
            reject(r);
          });

      });
    },
    iniciarSesion(context, datos) {
      return new Promise((resolve, reject) => {
        api.iniciarSesion(datos)
          .then((respuesta) => {
            let r = respuesta.data
            if (r.respuesta) {
              context.commit('asignaSesion', r);
              utils.EventBus.$emit('sesion-iniciada');
              resolve(r);
            } else {
              resolve(r);
            }
          }).catch((r) => {
            reject(r);
          });

      });
    },
    renovarSesion(context, datos) {
      console.log("Renovando sesion");
      return new Promise((resolve, reject) => {
        api.renovarSesion(datos)
          .then((respuesta) => {
            let r = respuesta.data
            if (r.respuesta) {
              context.commit('reasignaSesion', r);
              utils.EventBus.$emit('sesion-iniciada');
              resolve(r);
            } else {
              resolve(r);
            }
          }).catch((r) => {
            reject(r);
          });

      });
    },
    terminarSesion(context) {
      context.commit('terminaSesion');
    },
    restaurarSesion(context) {
      context.commit('restauraSesion');
    },
  },
  getters: {
    sesionIniciada: state => () => {
      if (state.token == null)
        return false;
      return utils.jwtValido(state.token);
    },
    usuario(state) {
      if (!utils.jwtValido(state.token)) return null;
      return state.usuario;
    },
    permisos(state) {
      return state.permisos;
    },
    ciclo(state) {
      return state.ciclo;
    },
    token(state) {
      return state.token;
    },
    tienePermiso: (state) => (que) => {
      if (!utils.jwtValido(state.token)) return false;
      if (state.permisos == null || state.permisos.length == 0) {
        return false;
      }
      return state.permisos.indexOf(que) > -1;
    },
    filtros(state) {
      return state.filtros;
    },

  },
  modules: {
  }
})
