<template>
  <div>
    <div class="card">
      <h2 class="card-header">Inconsistencias: {{ titulo }}</h2>
      <div> <!-- Encabezado del reporte -->
        <filtros-membresia
          v-model="busqueda"
          ref="busqueda"
          :tipo-membresia="tipo"
          :mostrar-estado="false"
          :mostrar-zona="false"
          :mostrar-rol="false"
          :mostrar-nivel="false"
          :mostrar-buscar-membresia="false"
          @input="actualizar"
        />
        <table id="mi-tabla">
          <tbody>
            <tr>
              <th class="titulo" id="tabla-encabezado">Ciclo:</th>
              <td> {{ reportes[0]?.ciclo }} </td>
            </tr>
          </tbody>
        </table>
      </div> <!-- Fin del contendor Encabezado del Reporte -->

      <div id="contenedor"> <!-- Contenedor Principal --> 
        <div class="card-body"> <!--Datos de Inconsistencias-->
          <div class="form-row">
            <table>
              <thead>
                <tr>
                  <td style = "display:inline-table"></td>
                  <th>Total de Registros</th>
                  <th>Registros Inconsistentes</th>
                  <th>Registros Correctos</th>
                  <th>Porcentaje correcto (%)</th>
                </tr>
              </thead>
              <tbody >
                <tr v-for="reporte in reportes" :key="reporte.tipo"> <!--Inconsistencias-->
                  <td scope="TituloInconsistencia"> {{ reporte.tipo }}</td>
                  <td class="text-center"> {{ reporte.total }} </td>
                  <td class="text-center"> {{ reporte.incorrectos }} </td>
                  <td class="text-center"> {{ reporte.correctos }} </td>
                  <td class="text-center"  v-bind:class="color(reporte.porcentaje)"> {{ reporte.porcentaje }} </td>
                </tr>
              </tbody>
            </table>
          </div> <!-- form-row Tabla -->
        </div> <!-- card-body -->
      </div>  <!-- Contenedor Principal-->

      <div class="card-body"> <!-- Tabla de Rangos -->
          <div class="form-row">
            <table>
              <thead>
                <tr>
                  <th colspan="4" style="text-align: center;" scope="rowFondoNegro">Tabla de Rangos</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th style="text-align: center; width: 15%; background-color: rgb(248, 181, 181);">Menos de 50%</th>
                  <th style="text-align: center; width: 15%; background-color: rgb(159, 209, 250);">De 51% a 80%</th>
                  <th style="text-align: center; width: 15%; background-color: rgb(255, 255, 159);">De 81% a 90%</th>
                  <th style="text-align: center; width: 15%; background-color: rgb(199, 250, 199);">De 91% a 100%</th>
                </tr>
              </tbody>
            </table>
          </div> <!-- form-row form-row Tabla-->
        </div> <!-- card-body Tabla de Rangos -->

    </div> <!-- Del Card principal-->
  </div> <!-- Del Template-->
</template>

<script>
import api from "@/api";
import { Constantes } from "@/api";
import FiltrosMembresia from "@/components/FiltrosMembresia.vue";
import moment from "moment";

export default {
  name: "ActasConsultar",
  components: { FiltrosMembresia },
  data() {
    return {
      Constantes: Constantes,
      busqueda: {},
      tipo: 1,
      reportes: [],
      guardando: false,
      permisos: {
        agregar: ["", "acta-agregar"],
        editar: ["", "acta-editar"],
        estatus: ["", "acta-estatus"],
      },
      tipo_mem: Number.parseInt(this.$route.params.tipo),
      titulo: Constantes.TIPOS_MEMBRESIA[Number.parseInt(this.$route.params.tipo)],
    };
  },
  //  };
  methods: {
    getFormattedDate(date) {
        return moment(date).format("DD-MM-YYYY")
    },
    actualizar() {
      this.reporteInconsistencias(this.tipo);
    },
    reporteInconsistencias: function (tipo_id) { //ReporteInconsistencias
      api.reporteInconsistencias(tipo_id,this.busqueda).then((r) => { //ReporteInconsistencias
        this.reportes = r.data; 
      });
    },
    color(valor) {
      if (valor> 90) {
        return 'verde';
      }
      if (valor > 80) {
        return 'amarillo';
      }
      if (valor > 50) {
        return 'azul';
      }
      return 'rojo';
    }

  },
  computed: {},
  mounted: function () {
    if (!this.$store.getters.usuario) {
      this.$store.dispatch('restaurarSesion')
    }

    this.$watch(
      () => this.$route.params,
      async () => {
        this.tipo_mem=Number.parseInt(this.$route.params.tipo);
        this.titulo = Constantes.TIPOS_MEMBRESIA[this.tipo_mem];
        this.reporteInconsistencias(this.tipo_mem); //ReporteInconsistencias
      }
    );

    this.tipo_mem=Number.parseInt(this.$route.params.tipo);
    this.titulo = Constantes.TIPOS_MEMBRESIA[this.tipo_mem];
    this.reporteInconsistencias(this.tipo_mem); //ReporteInconsistencias
 
  },
};
</script>

<style scoped>
.verde {
  background-color: rgb(199, 250, 199);
}
.amarillo {
  background-color: rgb(255, 255, 159);
}
.azul {
  background-color: rgb(159, 209, 250);
}
.rojo {
  background-color: rgb(248, 181, 181);
}
.acuerdo-number {
  font-size: 1.2em; /* Tamaño de fuente más grande */
  color: #007bff; /* Color de resaltado, puedes ajustarlo a tu preferencia */
  font-weight: bold; /* Texto en negrita */
}

.underlined-text {
  text-decoration: underline; /* Subrayar el texto */
}

table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

body {
  margin: 0;
  padding: 0;
  display: flex;
}

#tabla-encabezado {
  width: 15%;
  padding: 20px; /* Espacio para otros contenidos o espacio en blanco */
  text-align: right; /* Alineación a la derecha */
}

#tabla-RolPrincipal {
  width: 52%;
  padding: 20px; /* Espacio para otros contenidos o espacio en blanco */
  
  text-align: right; /* Alineación a la derecha */
}

#mi-tabla {
  width: 100%;
  border-collapse: collapse;
}

#mi-tabla th, #mi-tabla td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align:left;
}

#mi-tabla th {
  background-color: #f2f2f2;
}

.titulo {
  font-weight: bold;
}

#contenedor {
  width: 100%;
  border: 1px solid #cccc;
  padding: 10px;
  position: relative;
  align-self: center;
}

#cuadro-firma {
  width: 100%;
  height: 150px; /* Ajusta la altura según tus necesidades */
  border: 1px solid #000;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

#linea-firma, #linea-cargo {
  width: 80%; /* Ajusta el ancho según tus necesidades */
  border-top: 1px solid #000;
  margin-top: 25px;
}

#label-el, #label-cargo {
  width: 100%;
  text-align: center;
  font-weight: bold;
  margin-top: 5px;
}

tr td[scope="TituloInconsistencia"] {
  background-color: #585858;
  color: white;
  font-weight: bold;
  text-align: right;
  width: 20%;
}

tr th[scope="rowFondoNegro"] {
  background-color: #585858;
  color: white;
  font-weight: bold;
  text-align: left;
}

</style>
