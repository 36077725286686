<template>
  <div>
    <div class="card">
      <h2 class="card-header">Formato S-07: Reporte de Baja</h2>
      <div id="tabla-encabezado"> <!-- Datos del reporte -->
        <table id="mi-tabla">
          <tbody>
            <tr>
              <th class="titulo">Diócesis:</th>
              <td><!--{{ reporte.general.diocesis }}--></td>
            </tr>
            <tr>
              <th class="titulo">Sector:</th>
              <td><!--{{ reporte.general.sector }}--></td>
            </tr>
            <tr style="border-bottom: 1px solid black;">
              <th class="titulo">Zona:</th>
              <td><!--{{ reporte.general.zona }}--></td>
            </tr>
            <tr>
              <th class="titulo">Ciclo:</th>
              <td><!--{{ reporte.general.ciclo }}--></td>
            </tr>
          </tbody>
        </table>
      </div> <!-- Del contendor Datos del Reporte -->

      <div id="contenedor"> <!-- Contenedor Principal-->
        <div class="card-body"> <!--Datos principales Él y Ella-->
          <div class="form-row">
            <table>
              <thead>
                <tr>
                  <td style = "display:inline-table"></td>
                  <th>Nombres</th>
                  <th>Primer Apellido</th>
                  <th>Segundo Apellido</th>
                </tr>
              </thead>
              <tbody>
                <tr> <!--Renglón 1-->
                  <td scope="rowEl">Él</td>
                  <td class="text-center"><!--{{ reporte.datos.NombresEl['1'] }}--></td>
                  <td class="text-center"><!--{{ reporte.datos.PrimerApellidoEl['2'] }}--></td>
                  <td class="text-center"><!--{{ reporte.datos.SegundoApellidoEl['3'] }}--></td>
                </tr>
                <tr> <!--Renglón 2-->
                  <td scope="rowElla">Ella</td>
                  <td class="text-center"><!--{{ reporte.datos.NombresElla['1'] }}--></td>
                  <td class="text-center"><!--{{ reporte.datos.PrimerApellidoElla['2'] }}--></td>
                  <td class="text-center"><!--{{ reporte.datos.SegundoApellidoElla['3'] }}--></td>
                </tr>
                <tr>
                  <th style="text-align: right;">ID:</th>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div> <!-- form-row form-row Tabla-->
        </div> <!-- card-body Datos principales Él y Ella -->

        <div class="card-body"> <!--Datos del Domicilio-->
          <div class="form-row">
            <table>
              <thead>
                <tr>
                  <th colspan="2" scope="rowFondoNegro">Domicilio Particular:</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th style="text-align: right; width: 15%;">Municipio</th>
                  <td style="width: 20%;"></td>
                  <th style="text-align: right; width: 15%;">Colonia</th>
                  <td style="width: 30%;"></td>
                  <th style="text-align: right; width: 10%;">Teléfono</th>
                  <td style="width: 10%;"></td>
                </tr>
              </tbody>
            </table>
          </div> <!-- form-row form-row Tabla-->
        </div> <!-- card-body Datos del Domicilio -->

        <div class="card-body"> <!-- Datos de la deserción -->
          <div class="form-row">
            <table>
              <tbody>
                <tr>
                  <th style="text-align: right; width: 15%;">Nivel del que se retiraron:</th>
                  <td style="width: 20%;"></td>
                  <th style="text-align: right; width: 15%;">Servicio que prestaban:</th>
                  <td style="width: 30%;"></td>
                </tr>
                <tr>
                  <th style="text-align: right; width: 10%;">Motivo por el que se retiraron:</th>
                  <td colspan="3" style="width: 10%;"></td>
                </tr>
                <tr>
                  <td style = "display:inline-table;"></td>
                  <td colspan="3">&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </div> <!-- form-row form-row Tabla-->
        </div> <!-- card-body Datos de la deserción -->

        <div id="tabla-DatosBaja"> <!-- Datos de la baja -->
          <table>
            <tbody>
              <tr>
                <th style="text-align: right;">Fecha de Baja:</th>
                <td class="text-center" style="width: 30%;"></td>
                <td style = "display:inline-table;"></td>
                <th scope="rowFondoNegro">Baja Temporal</th>
                <td class="text-center" style="width: 10%;"></td>
                <td style = "display:inline-table;"></td>
                <th scope="rowFondoNegro">Baja Definitiva</th>
                <td class="text-center" style="width: 10%;"></td>
              </tr>
            </tbody>
          </table>
        </div> <!-- Datos de la baja -->

      <div> <!-- card-body Firmas -->
        <br/>
        <br/>
        <br/>
        <h6 align="Center"><b>ENTERADOS:</b></h6>
        <br/>
        <!--<div id="Cuadro-responsables">
          <p id="Nombre-responsable">{{ reporte.datos.enterados[1] }}</p>
          <p id="Nombre-responsable">{{ reporte.datos.enterados[2] }}</p>
          <p id="Nombre-responsable">{{ reporte.datos.enterados[3] }}</p>
        </div>-->
        <div id="Cuadro-firmas">
          <p id="Linea-firma">Matrimonio Responsable de Área I</p>
          <p id="Linea-firma">Matrimonio Zonal</p>
          <p id="Linea-firma">Matrimonio que se retira</p>
        </div>
      </div>

      </div>  <!-- Contenedor Principal-->
    </div> <!-- Del Card principal-->
  </div> <!-- Del Template-->
</template>
<script>
import api from "@/api";
import { Constantes } from "@/api";

export default {
  name: "ActasConsultar",
  components: {  },
  data() {
    return {
      Constantes: Constantes,
      reporte: {},
      guardando: false,
      permisos: {
        agregar: ["", "acta-agregar"],
        editar: ["", "acta-editar"],
        estatus: ["", "acta-estatus"],
      },
    };
  },
  //  };
  methods: {
    reporteS07: function (sector) {
      api.reporteS07(sector).then((r) => {
        this.reporte = r.data; 
      });
    },

  },
  computed: {},
  mounted: function () {
    const sector = this.$route.params.id ?? 0;
    this.reporteS07(sector);
  },
};
</script>
<style scoped>
.acuerdo-number {
  font-size: 1.2em; /* Tamaño de fuente más grande */
  color: #007bff; /* Color de resaltado, puedes ajustarlo a tu preferencia */
  font-weight: bold; /* Texto en negrita */
}

.underlined-text {
  text-decoration: underline; /* Subrayar el texto */
}

table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

body {
  margin: 0;
  padding: 0;
  display: flex;
}

#tabla-encabezado {
  width: 15%;
  padding: 20px; /* Espacio para otros contenidos o espacio en blanco */
  text-align: right; /* Alineación a la derecha */
}

#tabla-DatosBaja {
  width: 80%;
  padding: 20px; /* Espacio para otros contenidos o espacio en blanco */
  text-align: left; /* Alineación a la derecha */
}

#mi-tabla {
  width: 100%;
  border-collapse: collapse;
}

#mi-tabla th, #mi-tabla td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align:left;
}

#mi-tabla th {
  background-color: #f2f2f2;
}

.titulo {
  font-weight: bold;
}

#contenedor {
  width: 100%;
  border: 1px solid #cccc;
  padding: 10px;
  position: relative;
  align-self: center;
}

#Cuadro-firmas {
  width: 100%;
  height: 150px; /* Ajusta la altura según tus necesidades */
  border: 0px solid #000;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: -7%;
}

#Linea-firma {
  width: 50%; /* Ajusta el ancho según tus necesidades */
  border-top: 1px solid #000;
  margin-left: 10%;
  margin-top: 25px;
  padding: 1%;
  text-align: center;
  font-weight: bold;
}

#Cuadro-responsables {
  width: 100%;
  height: 33px; /* Ajusta la altura según tus necesidades */
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: -7%;
}

#Nombre-responsable {
  width: 50%; /* Ajusta el ancho según tus necesidades */
  margin-left: 10%;
  margin-top: 25px;
  padding: 1%;
  text-align: center;
  font-weight: bold;
}

tr td[scope="rowEl"] {
  background-color: #585858;
  color: white;
  font-weight: bold;
  text-align: right;
}

tr td[scope="rowElla"] {
  background-color: #585858;
  color: white;
  font-weight: bold;
  text-align: right;
}

tr th[scope="rowFondoNegro"] {
  background-color: #585858;
  color: white;
  font-weight: bold;
  text-align: right;
}

</style>
