<template>
  <div>
    <h4>
      <font-awesome-icon icon="calendar" /> {{ titulo }}
    </h4>
    <div class="card">
      <h6 class="card-header">Datos del Evento</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-3">
            <label for="evento_id">ID del Evento</label>
            <span class="form-info">
              {{ evento.evento_id }}
            </span>
          </div>
          <div class="form-group col-md-3">
            <label for="diocesis_id">Diócesis</label>
            <div class="form-info">
              {{ diocesis.filter(s => s.diocesis_id == evento.diocesis_id)[0]?.nom_diocesis }}
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="sector_id">Sector o presector</label>
            <div class="form-info">
              {{ sectores.filter(s => s.sector_id == evento.sector_id)[0]?.nom_sector }}
            </div>
          </div>
          <div class="form-group col-md-3">
            <b-form-group label="Nombre del curso">
              <div class="form-info">
                {{ formaciones.filter(f => f.formacion_id == evento.formacion_id)[0]?.nom_formacion }}
              </div>
            </b-form-group>
          </div>
          <div class="form-group col-md-3">
            <label for="fecha_ini">Fecha de Inicio:</label>
            <div class="form-info">
              {{ evento.fecha_ini }}
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="hora_ini">Hora de Inicio:</label>
            <div class="form-info">
              {{ evento.hora_ini }}
            </div>            
          </div>
          <div class="form-group col-md-3">
            <label for="fecha_fin">Fecha de término:</label>
            <div class="form-info">
              {{ evento.fecha_fin }}
            </div>            
          </div>
          <div class="form-group col-md-3">
            <label for="hora_fin">Hora de término:</label>
            <div class="form-info">
              {{ evento.hora_fin }}
            </div>            
          </div>
          <div class="form-group col-md-3">
            <b-form-group label="Facilitadores">
              <div class="form-info">
              {{ roles.filter(r => r.rol_id == evento.rol_id)[0]?.nom_rol }}
              </div>
            </b-form-group>
          </div>
          <div class="form-group col-md-3">
            <label for="lugar_evento">Lugar del evento</label>
            <div class="form-info">
              {{ evento.lugar }}
            </div>            
          </div>
          <div class="form-group col-md-6">
            <label for="observaciones">Observaciones</label>
            <div class="form-info">
              {{ evento.observaciones }}
            </div>            
          </div>
          <div class="form-group col-md-3" v-if="evento.adjunto">
            <label for="adjunto" class="upload-label">Adjuntar archivo</label>
            <div class="form-info">
              <a target="_blank" :href="`${API_URL}/archivo/${evento.adjunto?.ruta}`" >
                <font-awesome-icon icon="file-pdf" />
                Documento.pdf
              </a>
            </div>
          </div>
        </div>
        <!-- form-row Datos del evento -->
      </div>
      <!-- card-body Datos del evento -->
    </div>
    <!-- card Datos del evento -->
    <br />
    <div class="col-md-12 text-center botones-accion">
      <button class="btn btn-secondary" v-on:click.stop.prevent="$router.go(-1)">
        Regresar
      </button>
    </div>
    <br />
    <div class="card">
      <h6 class="card-header">Participantes</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="col-md-6">
            No. participantes: {{ evento.asistentes.length }}
          </div>
          <div class="col-md-12 text-right botones-accion">
            <b-button @click="exportarExcel" :disabled="evento.asistentes.length == 0">Exportar Excel</b-button>
          </div>
          <!-- Este grid representa la tabla donde se visualizarán los registros al evento -->
          <br />
          <ve-table style="width: 100%" rowKeyFieldName="membresia_id" border-y :fixed-header="true" :columns="columnas"
            :table-data="evento.asistentes" :max-height="400" :cell-selection-option="{ enable: false }"
            :virtualScrollOption="{ enable: true }" />
        </div>
        <!-- form-row Asistencia -->
      </div>
      <!-- card-body Asistencia -->
    </div>
    <br />
    <div class="card">
      <h6 class="card-header">Participantes No MFC</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="col-md-6">
            No. participantes no mfc: {{ evento.asistentes_no.length }}
          </div>
          <div class="col-md-6 text-right botones-accion">
            <b-button @click="exportarExcelNoMfc" :disabled="evento.asistentes_no.length == 0">Exportar Excel</b-button>
          </div>
          <!-- Este grid representa la tabla donde se visualizarán los registros al evento -->
          <br />
          <ve-table style="width: 100%" rowKeyFieldName="evento_no_mfc_id" border-y :fixed-header="true"
            :columns="columnasno" :table-data="evento.asistentes_no" :max-height="200"
            :cell-selection-option="{ enable: false }" :virtualScrollOption="{ enable: true }" />
        </div>
        <!-- form-row Asistencia -->
      </div>
      <!-- card-body Asistencia -->
    </div>
    <!-- card Asistencia -->
  </div>
</template>
<script>
import api from "@/api";
import { Constantes } from "@/api";
import { required } from "vuelidate/lib/validators";
import { telefono, letras, correo, numeros } from "@/libs/validadores";
import { exportarExcel } from "@/utils"

function debounce(fn, delay) {
  var timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    var args = arguments
    var that = this
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
  }
}

export default {
  name: "eventosEditar",
  components: {  },
  data() {
    return {
      diocesis: [],
      sectores: [],
      formaciones: [],
      guardando: false,
      tipo: {},
      tipo_membresia: {},
      roles: [],
      tipos_membresia: [],

      nomfc: {},
      id: { id: null },
      idd: { diocesis_id: null, tipo_membresia_id: null, id: null },
      nombre: { tipo_membresia_id: null, nombre: null, membresia_id: null },

      asistentesEncontrados: [],
      Constantes: Constantes,
      titulo: "",
      evento: {
        evento_id: null,
        fecha_ini: null,
        hora_ini: null,
        fecha_fin: null,
        hora_fin: null,
        lugar: null,
        adjunto: null,
        rol_id: null, //este campo al parecer no existe en la tabla "evento". Anteriormente era "capacitador"
        observaciones: null,
        asistentes: [],
        asistentes_no: [],
      },

      permisos: {
        agregar: ["", "evento-agregar"],
        editar: ["", "evento-editar"],
        estatus: ["", "evento-estatus"],
      },
      columnas: [
        {
          field: "membresia_id",
          key: "a",
          title: "Id",
          align: "left",
        },
        {
          field: "diocesis",
          key: "b",
          title: "Diócesis",
          align: "left",
        },
        {
          field: "sector",
          key: "c",
          title: "Sector",
          align: "left",
        },
        {
          field: "nom_tipo_membresia",
          key: "d",
          title: "Tipo miembro",
          align: "left",
        },
        {
          field: "nombre_completo",
          key: "e",
          title: "Nombre",
          align: "left",
        },
        {
          field: "nom_rol",
          key: "f",
          title: "Rol",
          align: "left",
        },

      ],
      columnasno: [
        {
          field: "evento_no_mfc_id",
          key: "a",
          title: "Id",
          align: "left",
        },
        {
          field: "nombre",
          key: "b",
          title: "Nombre",
          align: "left",
        },
        {
          field: "direccion",
          key: "c",
          title: "Dirección",
          align: "left",
        },
        {
          field: "telefono",
          key: "d",
          title: "Teléfono",
          align: "left",
        },
        {
          field: "casados_iglesia",
          key: "e",
          title: "Casados iglesia",
          align: "left",
        },


      ],
    };
  },
  validations() {
    return {

      evento: {
        diocesis_id: { required },
        sector_id: { required },
        fecha_ini: { required },
        hora_ini: { required },
        fecha_fin: { required },
        hora_fin: { required },
        rol_id: { required: false }, // En el sistema anterior este campo era "capacitador"
        lugar: { required },
        formacion_id: { required },
      },
      id: {
        id: { required, numeros }
      },
      idd: { diocesis_id: { required }, tipo_membresia_id: { required }, id: { required, numeros } },
      nombre: {
        nombre: { required, letras },
        membresia_id: { required },
      },
      nomfc: {
        nombre: { required, letras },
        telefono: { telefono },
        correo: { correo },
      }
    };
  },
  //  };
  methods: {
    exportarExcel() {
      exportarExcel(this.evento.asistentes);
    },
    exportarExcelNoMfc() {
      exportarExcel(this.evento.asistentes_no);
    },
    cambioNombre() {
      console.log(this.nombre.nombre);
      const nombre = this.nombre.nombre.trim();
      if (nombre && nombre.length >= 3) {
        api.registrarBuscarParticipanteNombre(this.nombre.tipo_membresia_id ?? 0, this.nombre.nombre).then((r => {
          const resp = r.data;
          this.nombre.membresia_id = null;
          if (resp.respuesta) {
            this.asistentesEncontrados = r.data.encontrados;
          } else {
            this.$parent.error(resp.mensaje);
          }
        }))
      }
    },

    guardarNombre() {
      this.$v.nombre.$touch();
      if (this.$v.nombre.$invalid) {
        this.$parent.error("Faltan datos por registrar o existen datos incorrectos");
        return;
      }
      if (this.evento.asistentes.some(p => p.membresia_id == this.nombre.membresia_id)) {
        this.$parent.error("Participante ya registrado");
        return;
      }
      api.registrarParticipanteId(this.evento.evento_id, this.nombre.membresia_id).then((response) => {
        const resp = response.data;
        if (resp.respuesta) {
          this.evento.asistentes.unshift(resp.asistente);
          this.$refs.nombre.hide();
        } else {
          this.$parent.error(resp.mensaje);
        }
      });
    },

    guardarId() {
      this.$v.id.$touch();
      if (this.$v.id.$invalid) {
        this.$parent.error("Faltan datos por registrar o existen datos incorrectos");
        return;
      }
      if (this.evento.asistentes.some(p => p.membresia_id == this.id.id)) {
        this.$parent.error("Participante ya registrado");
        return;
      }
      api.registrarParticipanteId(this.evento.evento_id, this.id.id).then((response) => {
        const resp = response.data;
        if (resp.respuesta) {
          this.evento.asistentes.unshift(resp.asistente);
          this.$refs.id.hide();
        } else {
          this.$parent.error(resp.mensaje);
        }
      });
    },

    guardarQr(qr) {
      api.registrarParticipanteQr(this.evento.evento_id, qr).then((response) => {
        const resp = response.data;
        if (resp.respuesta) {
          if (this.evento.asistentes.some(p => p.membresia_id == resp.asistente.membresia_id)) {
            this.$parent.error("Participante ya registrado");
            return;
          }
          this.evento.asistentes.unshift(resp.asistente);
          this.$refs.credencial.hide();
        } else {
          this.$parent.error(resp.mensaje);
        }
      });
    },

    guardarIdD() {
      this.$v.idd.$touch();
      if (this.$v.idd.$invalid) {
        this.$parent.error("Faltan datos por registrar o existen datos incorrectos");
        return;
      }
      api.registrarParticipanteIdd(this.evento.evento_id, this.idd.diocesis_id, this.idd.tipo_membresia_id, this.idd.id).then((response) => {
        const resp = response.data;
        if (resp.respuesta) {
          if (this.evento.asistentes.some(p => p.membresia_id == resp.asistente.membresia_id)) {
            this.$parent.error("Participante ya registrado");
            return;
          }

          this.evento.asistentes.unshift(resp.asistente);
          this.$refs.idd.hide();
        } else {
          this.$parent.error(resp.mensaje);
        }
      });
    },
    guardarNoMfc() {
      this.$v.nomfc.$touch();
      if (this.$v.nomfc.$invalid) {
        this.$parent.error("Faltan datos por registrar o existen datos incorrectos");
        return;
      }
      api.registrarParticipanteNo(this.evento.evento_id, this.nomfc).then((response) => {
        const resp = response.data;
        if (resp.respuesta) {
          this.evento.asistentes_no.unshift(resp.asistente);
          this.$refs.nomfc.hide();
        } else {
          this.$parent.error(resp.mensaje);
        }
      });
    },

    eliminarParticipante(datos, pos) {
      this.$parent.confirmar(`¿Desea eliminar al participante ${datos.nombre_completo}?`).then(() => {
        api.eliminarParticipante(datos.evento_det_id).then(r => {
          if (r.data.respuesta) {
            this.$parent.mensaje("Participante eliminado");
            this.evento.asistentes.splice(pos, 1);
          } else {
            this.$parent.error(r.data.mensaje);
          }
        });
      });
    },
    eliminarParticipanteNo(datos, pos) {
      this.$parent.confirmar(`¿Desea eliminar al participante ${datos.nombre}?`).then(() => {
        api.eliminarParticipanteNo(datos.evento_no_mfc_id).then(r => {
          if (r.data.respuesta) {
            this.$parent.mensaje("Participante eliminado");
            this.evento.asistentes_no.splice(pos, 1);
          } else {
            this.$parent.error(r.data.mensaje);
          }
        });
      });
    },
    resetModalValues() {
      this.nombre = "";
      this.direccion = "";
      this.telefono = "";
      this.correo = "";
      this.casado_iglesia = false;
    },


    submitForm() {
      // Aquí ponemos el código de lo que queremos hacer con los datos capturados
      console.log("Nombre:", this.nombre);
      console.log("Dirección:", this.direccion);
      console.log("Teléfono:", this.telefono);
      console.log("Correo electrónico:", this.correo);
      console.log("Casado por la Iglesia", this.casado_iglesia);

      //Aquí van los otros campos que se quieren registrar en el evento
      this.closeModal();
    },

    guardar() {
      this.$v.evento.$touch();
      let valido = !this.$v.evento.$invalid;
      if (valido) {
        if (this.guardando) {
          return;
        }
        this.guardando = true;

        api
          .guardarEvento(this.evento)
          .then((response) => {
            let datos = response.data;
            if (datos != null && datos.respuesta) {
              this.evento.evento_id = datos.id;
              this.$parent.mensaje("Información guardada correctamente");
            } else {
              this.$parent.error(
                "Error al guardar la información: " +
                (datos != null ? datos.mensaje : "error interno")
              );
            }
            this.guardando = false;
          })
          .catch((error) => {
            this.guardando = false;
            this.$parent.error("Error al guardar la información: " + error);
          });
      } else {
        this.$parent.error(
          "Faltan datos por registrar o existen datos incorrectos"
        );
      }
    },
    listaTiposMembresia: function () {
      api.listarTipoMembresias().then((r) => {
        this.tipos_membresia = r.data;
      });
    },

    listaFormaciones: function (tipo_evento, tipo_membresia) {
      api.listarFormaciones(tipo_evento, tipo_membresia).then((r) => {
        this.formaciones = r.data;
      });
    },
    listaDiocesis: function () {
      api.listarDiocesis().then((r) => {
        this.diocesis = r.data;
      });
    },

    listaRoles() {
      api.listarRolesTodos().then((r) => {
        if (Array.isArray(r.data)) {
          this.roles = r.data;
        } else {
          this.roles = [];
        }
      });
    },

    actualizaSectores: function () {
      var id = this.evento.diocesis_id;
      this.sectores = [];
      api.listarSectores(id).then((r) => {
        this.sectores = r.data;
      });
    },

    obtenerEvento: function (id) {
      api.obtenerEvento(id).then((r) => {
        this.evento = r.data.evento;
        this.tipo = this.evento.tipo_formacion_id;
        this.tipo_membresia_id = this.evento.tipo_membresia_id;
        this.actualizaSectores();
        this.listaFormaciones(this.tipo, this.tipo_membresia_id);
        this.titulo =
          "Edición de eventos de " + Constantes.TIPOS_EVENTOS[this.tipo];
      });
    },
  },
  computed: {},
  mounted: function () {
    const tipo = parseInt(this.$route.params.tipo) ?? 0;
    if (this.$route.params.id) {
      this.obtenerEvento(this.$route.params.id);
    } else {
      this.tipo = Number.parseInt(this.$route.params.tipo);
      this.tipo_membresia = Number.parseInt(this.$route.params.tipo_membresia);
      this.titulo = "Agregar evento de " + Constantes.TIPOS_EVENTOS[tipo];
      this.listaFormaciones(this.tipo, this.tipo_membresia);
    }
    this.listaDiocesis();
    this.listaRoles();
    this.listaTiposMembresia();
  },
  created() {
    this.cambioNombreDebounced = debounce(() => {
      this.cambioNombre();
    }, 2500);
  },
};
</script>
<style scoped></style>
