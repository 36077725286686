<template>
  <b-card header="Filtros">
        <b-form-row>
          <b-col md="4">
            <b-form-group label="Región">
              <b-form-select
                v-model="busqueda.region_id"
                :options="listadoRegiones"
                value-field="region_id"
                text-field="nom_region"
                v-on:change="filtrarDiocesis"
              >
                <b-form-select-option :value="null">Todos</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Diócesis">
              <b-form-select
                v-model="busqueda.diocesis_id"
                :options="listadoDiocesisFiltro"
                value-field="diocesis_id"
                text-field="nom_diocesis"
                v-on:change="actualizarSectores"
              >
                <b-form-select-option :value="null">Todos</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Sector">
              <b-form-select
                v-model="busqueda.sector_id"
                :options="listadoSectores"
                value-field="sector_id"
                text-field="nom_sector"
                @change="buscar"
              >
                <b-form-select-option :value="null">Todos</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="4" v-if="mostrarRol">
            <b-form-group label="Rol">
              <b-form-select
                v-model="busqueda.rol_id"
                :options="listadoRoles"
                value-field="rol_id"
                text-field="nom_rol"
                @change="buscar"
              >
                <b-form-select-option :value="null">Todos</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="4" v-if="mostrarNivel">
            <b-form-group label="Nivel">
              <b-form-select
                v-model="busqueda.nivel_id"
                :options="listadoNiveles"
                value-field="nivel_id"
                text-field="nom_nivel"
                @change="buscar"
              >
                <b-form-select-option :value="null">Todos</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="4" v-if="mostrarZona">
            <label class="form-label" >Zona</label>
            <input
                maxlength="10"
                class="form-control"
                v-model="busqueda.zona"
                @change="buscar"
              />
          </b-col>
          <b-col sm="4" v-if="mostrarEstado">
            <b-form-group label="Estatus">
              <b-form-select
                v-model="busqueda.estatus_id"
                :options="listadoEstatusMembresia"
                value-field="estatus_id"
                text-field="nom_estatus"
                @change="buscar"
              >
                <b-form-select-option :value="null">Todos</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-form-row>
        <hr>
        <form v-if="mostrarBuscarMembresia" @submit.prevent="buscarMembresia">
          <div class="row">
          <div class="form-group col-md-12">
            <label for="busqueda_por">Buscar miembros por</label>
            <div class="input-select-container d-flex">
              <select class="form-control custom-width" @change="limpiaIdNombre" v-model="busquedaMembresia.tipo">
                <option value="buscar_id">ID</option>
                <option value="buscar_nombre">Nombre o Apellido</option>
              </select>
              <input
              v-if="busquedaMembresia.tipo == 'buscar_id'"
                maxlength="10"
                class="form-control custom-width"
                v-model="busquedaMembresia.id"
              />
              <input
                v-if="busquedaMembresia.tipo == 'buscar_nombre'"
                maxlength="100"
                class="form-control custom-width"
                v-model="busquedaMembresia.nombre"
              />
            </div>
            &nbsp;
            <div class="button-container">
              <b-button type="submit"
                :disabled="$v.busquedaMembresia.$invalid"
                :title="busquedaMembresia.tipo === 'buscar_id' ? 'Teclea un valor numérico' : 'Teclea un valor alfabético'"
              >
                <font-awesome-icon icon="search" /> Buscar
              </b-button>
              &nbsp;
              <b-button title="Limpiar filtros" @click="limpiarBusqueda()">
                <font-awesome-icon icon="eraser" /> Limpiar
              </b-button>
            </div>
          </div>
          </div>
        </form>
    </b-card>
</template>
<script >
import api from "@/api";
import {  requiredIf } from "vuelidate/lib/validators";
import { numeros, letras } from "@/libs/validadores";
export default {
  name: "FiltrosMembresia",
  props: {
    value: {
        type: Object,
        required: true
      },
    mostrarRol: {
      type: Boolean,
      default: true,
    },
    mostrarNivel: {
      type: Boolean,
      default: true,
    },
    mostrarEstado: {
      type: Boolean,
      default: true,
    },
    mostrarBuscarMembresia: {
      type: Boolean,
      default: true,
    },
    mostrarZona: {
      type: Boolean,
      default: false,
    },
    tipoMembresia: Number,
    estatusId: {
      type: Number,
      default: 20
    },
  },
  data() {
    return {
      busqueda: this.value,
      busquedaMembresia: { },
      listadoRegiones: [],
      listadoDiocesis: [],
      listadoDiocesisFiltro: [],
      listadoSectores: [],
      listadoRoles: [],
      listadoNiveles: [],
      listadoEstatusMembresia: [],
    };
  },

  validations() {
    return {
      busquedaMembresia: { 
        id: { numeros, required: requiredIf ((m)=>m.tipo=='buscar_id')} ,
        nombre: { letras, required: requiredIf ((m)=>m.tipo=='buscar_nombre')} ,
      }
      
    };
  },
  methods: {
    limpiaIdNombre() {
      if (this.busquedaMembresia.tipo == 'buscar_id') {
        this.busquedaMembresia.nombre = null;
      } else {
        this.busquedaMembresia.id = null;
      }
    },
    onInputChange() {
      // Este espacio es cuando se quiera hacer una acción conforme se teclea en el "input"
      this.showLabel = false;
    },
    buscarMembresia() {
      let busqueda = {...this.busqueda};
      if (this.busquedaMembresia.tipo == 'buscar_id') {
        busqueda.id = this.busquedaMembresia.id;
      } else {
        busqueda.nombre = this.busquedaMembresia.nombre;
      }
      busqueda.tipo = this.busquedaMembresia.tipo;
      this.$store.dispatch("guardarFiltros", busqueda);
      this.$emit('input', busqueda);
    },
    limpiarBusqueda() {
      this.busqueda = {
        region_id:  null,
        diocesis_id: null,
        sector_id: null,
        rol_id:  null,
        nivel_id: null,
        estatus_id: this.estatusId,
      }
      this.busquedaMembresia = {
        nombre: null,
        id: null,
        tipo: "buscar_id"
      }
      this.buscar();
    },

    buscar() {
      const busqueda = { ...this.busqueda };
      this.$store.dispatch("guardarFiltros", busqueda);
      this.$emit('input', busqueda);
    },
    inicializarBusqueda() {
      if (!this.$store.getters.filtros) {
        this.$store.dispatch('consultarFiltros');
      }      
      const val = this.$store.getters.filtros;
      this.busqueda = {
        region_id: val.region_id ?? null,
        diocesis_id: val.diocesis_id ?? null,
        sector_id: val.sector_id ?? null,
        rol_id: val.rol_id ?? null,
        nivel_id: val.nivel_id ?? null,
        estatus_id: val.estatus_id ?? this.estatusId,
      };
      this.busquedaMembresia = {
        nombre: val.nombre ?? null,
        id: val.id ?? null,
        tipo: val.tipo ?? "buscar_id",
      }
      if (this.busquedaMembresia.id || this.busquedaMembresia.nombre) {
        this.buscarMembresia();
      } else {
        this.buscar();
      }
    },
    actualizarRegiones() {
      api.listarRegiones().then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoRegiones = r.data;
        } else {
          this.listadoRegiones = [];
        }
      });
    },
    actualizarDiocesis() {
      api.listarDiocesis().then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoDiocesis = r.data;
          this.listadoDiocesisFiltro = r.data;
        } else {
          this.listadoDiocesis = [];
        }
        if (this.busqueda.region_id) {
          this.filtrarDiocesis(false);
        }
      });
    },
    filtrarDiocesis(ui=true) {
      let existe = false;
      this.listadoDiocesisFiltro = this.listadoDiocesis.filter(d => {
        const agregar = this.busqueda.region_id == null || this.busqueda.region_id == d.region_id;
        if (agregar) {
          if (d.diocesis_id == this.busqueda.diocesis_id) {
            existe = true;
          }
        }
        return agregar;
      });
      if (!existe && ui) {
        this.busqueda.diocesis_id = null;
        this.actualizarSectores();
      }
      if(ui) {
        this.buscar();
      }
    },
    actualizarSectores(ui=true) {
      if (ui) {
        this.busqueda.sector_id = null;
      }
      if (this.busqueda.diocesis_id != null) {
        api.listarSectoresDiocesis(this.busqueda.diocesis_id).then((r) => {
          if (Array.isArray(r.data)) {
            this.listadoSectores = r.data;
          } else {
            this.listadoSectores = [];
          }
        });
      } else {
        this.listadoSectores = [];
      }
      if (ui) {
        this.buscar();
      }

    },
    actualizarRoles() {
      api.listarRoles(this.tipoMembresia).then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoRoles = r.data;
          if (this.busqueda.rol_id != null && !this.listadoRoles.some(r => r.rol_id == this.busqueda.rol_id)) {
            this.busqueda.rol_id = null;
            this.buscar();
          }
        } else {
          this.listadoRoles = [];
        }
      });
    },
    actualizarNiveles() {
      api.listarNiveles(this.tipoMembresia).then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoNiveles = r.data;
          if (this.busqueda.nivel_id != null && !this.listadoNiveles.some(r => r.nivel_id == this.busqueda.nivel_id)) {
            this.busqueda.nivel_id = null;
            this.buscar();
          }
        } else {
          this.listadoNiveles = [];
        }
      });
    },
    actualizarEstadosMembresia() {
      api.listarEstatusMembresia().then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoEstatusMembresia = r.data;
        } else {
          this.listadoEstatusMembresia = [];
        }
      });
    },
    inicializa() {
      this.inicializarBusqueda();

      this.actualizarRegiones();
      this.actualizarDiocesis();
      this.actualizarNiveles();
      this.actualizarSectores(false);
      if (this.mostrarRol) {
        this.actualizarRoles();
      }
      if (this.mostrarEstado) {
        this.actualizarEstadosMembresia();
      }
    }
  },
  mounted() {
    this.inicializa()
  },
}

</script>

<style>

  .input-select-container {
    display: flex; /* Hace que los elementos hijos se muestren en línea */
  }

  .input-select-container select.custom-width,
  .input-select-container input.custom-width {
    width: 250px; /* Establecer un ancho específico */
    margin-right: 10px; /* Espacio a la derecha entre los elementos del contenedor */
  }

  .button-container {
    /* Estilos para el contenedor del botón */
    display: flex;
    align-items: flex-end; /* Alinear el botón en la parte inferior */
  }

</style>