import { render, staticRenderFns } from "./EventosConsultar.vue?vue&type=template&id=311280f1&scoped=true"
import script from "./EventosConsultar.vue?vue&type=script&lang=js"
export * from "./EventosConsultar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "311280f1",
  null
  
)

export default component.exports